"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Url_1 = require("../../util/Url");
var JobsClient_1 = require("../base/JobsClient");
var axios_1 = require("axios");
var MapJobsClient = /** @class */ (function (_super) {
    __extends(MapJobsClient, _super);
    function MapJobsClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MapJobsClient.prototype.getListUrl = function (page) {
        return process.env.VUE_APP_API_URL + '/api/v1/map/proxy/list?' + Url_1.default.params({ page: page });
    };
    MapJobsClient.prototype.clientDetail = function (id) {
        return axios_1.default.post(this.getClientDetailUrl() + '?expand=humanPayload,payload', { id: id })
            .then(function (response) {
            console.log(response.data);
            return response.data;
        });
    };
    MapJobsClient.prototype.populateJob = function (job) {
        return {
            id: job.id,
            total: Number.parseInt(job.total),
            done: Number.parseInt(job.done),
            finalize: job.complete,
            error: null,
            created_at: new Date().getTime(),
            done_at: job.complete ? new Date().getTime() : null,
            downloadUrl: job.downloadUrl,
            payload: null,
            humanPayload: null,
        };
    };
    MapJobsClient.prototype.getClientDetailUrl = function () {
        return process.env.VUE_APP_API_URL + '/api/v1/map/client-detail';
    };
    return MapJobsClient;
}(JobsClient_1.default));
exports.default = MapJobsClient;
