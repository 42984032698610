<template>
  <div class="p-col-12">
    <div v-if="loadingData && !offerUrl">
      <ProgressBar :value="progress"/>
      <Message :closable="false">
        КП формируется. Идентификатор: {{ jobId }}
      </Message>
    </div>

    <div v-if="offerUrl">
      <Message severity="success" :closable="false">
        КП сформирован. <a v-bind:href="offerUrl">Скачать</a>
      </Message>
    </div>


    <div class="p-fluid">
      <div class="p-field">
        <label for="batch">Участок</label>
        <Dropdown
            id="batch"
            v-model="article"
            @filter="onSearch"
            :options="articles"
            :dropdown="true"
            field="name"
            :filter="true"
            :optionLabel="(value) => value.article + ' - ' + value.name"
            :optionValue="(value) => value.article"
            emptyFilterMessage="По вашему запросу ничего не найдено"
            @change="onBatchChange"
        />
      </div>

      <div class="p-field">
        <label for="discount">Наценка</label>
        <InputNumber id="discount" v-model="discount"/>
      </div>

      <div>
        <SignMapComponent :model-value="points" :batch-geojson="batch" @update:model-value="updatePoints"/>
      </div>

    </div>

    <div class="p-formgroup-inline">
      <div class="p-field">
        <Button v-on:click.prevent="addOffer">Добавить</Button>
      </div>

      <div class="p-field">
        <Button v-on:click.prevent="addOffers">Добавить все</Button>
      </div>

      <div class="p-field">
        <Button class="p-button-success" v-on:click.prevent="generate">Сгенерировать</Button>
      </div>
    </div>

    <div v-if="offers.length">
      <DataTable :value="offers">
        <Column field="article" header="Артикул"></Column>
        <Column field="price" header="Наценка">
          <template #body="slotProps">
            {{ slotProps.data.discount ? slotProps.data.discount : "Не указано" }}
          </template>
        </Column>
        <Column :exportable="false">
          <template #body="slotProps">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteOffer(slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </div>

  </div>
</template>

<script>
import _                from 'lodash';
import BatchSearcher    from "@/services/catalogue/BatchSearcher.js";
import InputNumber      from "primevue/inputnumber/InputNumber";
import DataTable        from "primevue/datatable/DataTable";
import Column           from "primevue/column/Column";
import ProgressBar      from "primevue/progressbar/ProgressBar";
import Message          from "primevue/message/Message";
import Button           from "primevue/button/Button";
import Dropdown         from "primevue/dropdown/Dropdown";
import SignGenerator    from "@/services/sign/SignGenerator.js";
import SignMapComponent from "@/components/sign/Map/SignMapComponent";

export default {
  components: {
    InputNumber     : InputNumber,
    DataTable       : DataTable,
    Column          : Column,
    ProgressBar     : ProgressBar,
    Message         : Message,
    Button          : Button,
    Dropdown        : Dropdown,
    SignMapComponent: SignMapComponent
  },
  data() {
    return {
      articles    : [],
      article     : null,
      points      : null,
      batch       : null,
      loadingData : false,
      discount    : undefined,
      offers      : [],
      jobId       : null,
      offerUrl    : false,
      progress    : 0,
      isMapVisible: true,
    }
  },
  batchSearcher : null,
  signGenerator: null,
  created() {
    this.batchSearcher  = new BatchSearcher;
    this.signGenerator = new SignGenerator;
  },
  methods: {
    onSearch(event) {
      this.search(event.value, this);
    },

    search: _.debounce((search, vm) => {
      vm.batchSearcher.search(search)
          .then(data => vm.articles = data);
    }, 1000),

    addOffer() {
      !this.article || (this.offers.push({
        article : this.article,
        discount: this.discount,
        points  : this.points,
        cacheEnabled: false,
      }) && this.clearInputs());
    },

    clearInputs() {
      this.price   = null;
      this.article = null;
    },

    addOffers() {
      this.getOffers().map((value) => this.offers.push(value))
    },

    getOffers() {
      return this.articles.map((value) => {
        return {article: value.article, discount: null}
      });
    },


    generate: function () {
      this.signGenerator.generateRequest(this.offers).then((generateResult) => {
        console.log(generateResult);
        generateResult && (this.loadingData = true) && (this.jobId = generateResult.id) && this.info(generateResult);
      })
    },

    info: function (generateResult) {
      this.signGenerator.statusRequest(generateResult).then((jobStatus) => {
        (jobStatus === false) && (this.loadingData = false);

        (jobStatus.url) ? (this.offerUrl = jobStatus.url) : this.updateProgress(jobStatus) && setTimeout(() => this.info(generateResult), 5000);
      })
    },

    updateProgress(jobStatus) {
      this.progress = this.$filters.percentage(jobStatus.done, jobStatus.total);
      return true;
    },

    deleteOffer(offer) {
      this.offers = this.offers.filter(val => !(
          val.article === offer.article
      ))
    },
    onBatchChange(e) {
      let article = e.value;

      this.batchSearcher.signGeometry(article).then((geometry) => {
        this.points = geometry.points;
        this.batch  = geometry.batch;
      });
    },
    updatePoints(value) {
      console.log(value);
      this.points = value;
    }
  }
}
</script>