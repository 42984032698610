<template>
  <div class="p-col-12 p-grid">
    <div id="map" class="p-col-6"></div>
    <div class="p-col-6" v-if="modelValue">
      <Button class="" @click="marker">Добавить координату</Button>
      <DataTable :value="modelValue" responsiveLayout="scroll">
        <template #header>
          <div class="table-header">
            Координаты
          </div>
        </template>
        <Column field="number" header="№"/>
        <Column field="coordinateX" header="X"/>
        <Column field="coordinateY" header="Y"/>
        <Column field="type" header="Тип">
          <template #body="slotProps">
            <Dropdown v-model="slotProps.data.type" :options="pointTypes" optionLabel="name" optionValue="value"/>
          </template>
        </Column>
        <Column header="Действия">
          <template #body="slotProps">
            <Button class="p-button-text" @click="deletePoint(slotProps.data)">&times;</Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
// import openlayer css for style
import "ol/ol.css";
import {OSM, Vector as VectorSource}              from 'ol/source';
import {Circle, Fill, Stroke, Style, Icon, Text}  from 'ol/style';
import {Feature, Map, View}                       from 'ol/index';
import {Point}                                    from 'ol/geom';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {defaults as defaultInteractions}          from "ol/interaction";
import Drag                                       from "@/services/sign/Drag";
import GeoJSON                                    from "ol/format/GeoJSON";
import {get as getProjection}                     from 'ol/proj';
import Button                                     from "primevue/button/Button";
import SelectButton                               from "primevue/selectbutton/SelectButton";
import Column                                     from "primevue/column/Column";
import DataTable                                  from "primevue/datatable/DataTable";
import Dropdown                                   from "primevue/dropdown/Dropdown";

export default {
  emits     : ['update:modelValue'],
  props     : {
    modelValue  : {
      type: Array
    },
    batchGeojson: null,
  },
  components: {
    Button      : Button,
    SelectButton: SelectButton,
    Column      : Column,
    DataTable   : DataTable,
    Dropdown    : Dropdown,
  },
  data() {
    return {
      map         : undefined,
      batchSource : null,
      pointsSource: null,
      pointTypes  : [
        {value: 'metal', name: 'Металл'},
        {value: 'wood', name: 'Дерево'},
      ]
    }
  },
  mounted() {
    this.initMap();
  },
  watch  : {
    batchGeojson: function (val, oldVal) {
      val && this.updateBatchFeature(val)
    },
    modelValue  : function (val, oldVal) {
      val && this.updatePointsFeature(val);
    }
  },
  methods: {
    initMap() {
      this.pointsSource = new VectorSource({projection: getProjection('EPSG:4326')});
      this.batchSource  = new VectorSource({projection: getProjection('EPSG:4326')});

      this.map = new Map({
        interactions: defaultInteractions().extend([new Drag(() => this.createTablePoints())]),
        layers      : [
          new TileLayer({
            source: new OSM(),
          }),
          new VectorLayer({
            source: this.batchSource,
            style : new Style({
              stroke: new Stroke({
                width: 3,
                color: [255, 0, 0, 1],
              }),
              fill  : new Fill({
                color: [0, 0, 255, 0.6],
              }),
            }),
          }),
          new VectorLayer({
            source: this.pointsSource,
          }),
        ],
        target      : 'map',
        view        : new View({
          projection: "EPSG:4326",
          zoom      : 2,
          center    : [0, 0],
        }),
      });
    },
    updateBatchFeature(polygon) {
      if (!polygon) {
        return false;
      }

      this.batchSource.clear();
      this.batchSource.addFeatures(new GeoJSON().readFeatures({
        type    : 'FeatureCollection',
        features: [polygon]
      }));
      this.fitMap();
    },
    fitMap() {
      if (!this.batchGeojson) {
        return false;
      }

      let extent = this.batchSource.getFeatures()[0].getGeometry().getExtent();
      this.map.getView().fit(extent, {padding: [100, 100, 100, 100]});
    },
    updatePointsFeature(points) {
      let pointFeatures = [];
      points.forEach((point) => {
        let feature = new Feature({
          geometry  : new Point([point.coordinateX, point.coordinateY]),
          properties: {
            number: point.number,
            type  : point.type,
          },
        })

        feature.setStyle(new Style({
          text : new Text({
            offsetY: 25,
            text   : point.number.toString(),
            font   : '15px Open Sans,sans-serif',
            fill   : new Fill({color: '#111'}),
            stroke : new Stroke({color: '#eee', width: 2})
          }),
          image: new Circle({
            radius: 9,
            fill  : new Fill({color: 'black'}),
          }),
        }));

        pointFeatures.push(feature);
      });

      this.pointsSource.clear();
      this.pointsSource.addFeatures(pointFeatures);
    },
    createTablePoints() {
      let points = [];
      this.pointsSource.getFeatures().forEach((point) => {
        let coordinates = point.getGeometry().getCoordinates();
        let properties  = point.get('properties');

        points.push({
          number     : properties.number,
          coordinateX: coordinates[0],
          coordinateY: coordinates[1],
          type       : properties.type,
        })
      })

      console.log(points);
      this.$emit('update:modelValue', points);
    },
    deletePoint(point) {
      let points = this.modelValue.filter(val => !(
          val.number === point.number
      ))

      let increment = 1;
      points.forEach((point, key) => {
        points[key].number = increment++;
      })

      this.$emit('update:modelValue', points);
    },
    marker() {
      let points = JSON.parse(JSON.stringify(this.modelValue));

      points.push({
        number     : 6,
        coordinateX: this.map.getView().getCenter()[0],
        coordinateY: this.map.getView().getCenter()[1],
        type       : 'metal',
      })

      this.$emit('update:modelValue', points);
    }

  },
};
</script>
<style scoped>
#map {
  z-index: 1;
  margin: 0;
  padding: 0;
  height: 500px;
}
</style>