<template>
  <div class="w-main uk-text-center uk-padding-large uk-padding-remove-horizontal">
    <img src="/assets/logo_mg.svg" class="w-main-logo" alt="">
    <h2 class="w-main-title">Коммерческое предложение по выносу межевых знаков</h2>
    <small v-if="!offer.complete">Несколько секунд и ваша <br class="uk-hidden@s">презентация будет готова...</small>
    <MapPresentationList :modelValue="offer" :human-payload="offer.humanPayload"></MapPresentationList>
  </div>
</template>

<script>

import MapGenerator from "@/services/catalogue/MapGenerator.js";
import MapPresentationList from "@/components/catalog/MapPresentationList";
import MapJobsClient from "@/services/catalogue/MapJobsClient.js";

export default {
  components: {
    MapPresentationList: MapPresentationList
  },
  name: "MapPresentationComponent",
  data() {
    return {
      offer: {id: null},
    }
  },
  props: {
    mapId: null,
  },
  jobClient: null,
  generator: null,
  created() {
    if (this.mapId === '' || !this.mapId) {
      return this.$router.push('/');
    }

    this.jobClient = new MapJobsClient();
    this.generator = new MapGenerator();
    this.generate().then(() => setInterval(() => {
      (!this.offer.downloadUrl) && this.showDetail()
    }, 2000));
  },
  mounted() {

  },
  methods: {
    showDetail() {
      this.jobClient ? this.jobClient.clientDetail(this.offer.id).then((offer) => {
        this.offer = (offer !== null ? offer : this.offer);
      }) : null;
    },

    generate() {
      return this.generator.clientGenerateRequest(this.mapId).then((generateResult) => {
        generateResult && (this.offer = generateResult) && this.showDetail();
      });
    },
  }
}
</script>

<style lang="css" scoped>

.w-main {
  background: #EAEDEF;
  height: 100vh;
}

.w-main-logo {
  width: 141px;
}

.w-main-title {
  font-size: 45px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 2rem 0;
  color: #000000;
}

small {
  color: #000000;
  font-size: 14px;
  margin-bottom: 2rem;
  display: block;
}

@media (max-width: 500px) {
  .w-main-title {
    font-size: 25px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 1.2rem 0;
    color: #000000;
  }

  .w-main-logo {
    width: 70px;
  }

}

</style>

<style>
.uk-spinner > * > * {
  color: red;
}
</style>
