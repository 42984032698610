<template>
  <RoadsPresentationList v-model="offer" :payload-columns="payloadColumns"></RoadsPresentationList>
</template>

<script>
import RoadsJobsClient       from "@/services/roads/RoadsSingleJobsClient.js";
import RoadsSingleGenerator  from "@/services/roads/RoadsSingleGenerator.js";
import RoadsPresentationList from "@/components/catalog/RoadsPresentationList";

export default {
  components: {
    RoadsPresentationList: RoadsPresentationList
  },
  data() {
    return {
      offer         : {id: null},
      payloadColumns: [
        {
          header: "Версия",
          field: "revisionName"
        },
        {
          header: "Въезд",
          field: "entranceName"
        },
        {
          header: "Пользователь",
          field: "userName"
        },
      ],
    }
  },
  props: {
    entranceId: null,
    revisionId: null,
    counterpartyId: null,
  },
  jobClient: null,
  roadsGenerator: null,
  created() {
    if (this.entranceId === '' || !this.entranceId) {
      return this.$router.push('/');
    }

    this.jobClient      = new RoadsJobsClient();
    this.roadsGenerator = new RoadsSingleGenerator();

    this.generate().then(() => setInterval(() => {
      !(this.offer.finalize && this.offer.downloadUrl) && this.showDetail()
    }, 1000));
  },
  mounted() {

  },
  methods: {
    showDetail() {
      this.jobClient
        ? this.jobClient.detail(this.offer.id).then((offer) => {
            this.offer = (offer !== null ? offer : this.offer);
          })
        : null;
    },

    generate() {
      return this.roadsGenerator.singleGenerateRequest(this.entranceId, this.revisionId, this.counterpartyId).then((generateResult) => {
        generateResult && (this.offer = generateResult) && this.showDetail();
      });
    },
  }
}
</script>