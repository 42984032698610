<template>
  <div class="p-col-12">
    <div v-if="loadingData && !offerUrl">
      <ProgressBar :value="progress"/>
      <Message :closable="false">
        КП формируется. Идентификатор: {{ jobId }}
      </Message>
    </div>

    <div v-if="offerUrl">
      <Message severity="success" :closable="false">
        КП сформирован. <a v-bind:href="offerUrl">Скачать</a>
      </Message>
    </div>


    <div class="p-fluid">
      <div class="p-field">
        <label for="src-project">Проект - боевой</label>
        <Dropdown
            id="src-project"
            v-model="pidProd"
            :options="srcProjects"
            :dropdown="true"
            field="name"
            :filter="true"
            :optionLabel="(value) => value.article + ' - ' + value.name"
            :optionValue="(value) => value.id"
            emptyFilterMessage="По вашему запросу ничего не найдено"
            @change="updateFieldsBySource"
        />
      </div>

      <div class="p-field">
        <label for="dst-project">Проект - услуги УК</label>
        <Dropdown
            id="dst-project"
            v-model="pidSegments"
            :options="dstProjects"
            :dropdown="true"
            field="name"
            :filter="true"
            :optionLabel="(value) => value.article + ' - ' + value.name"
            :optionValue="(value) => value.id"
            emptyFilterMessage="По вашему запросу ничего не найдено"
        />
      </div>

      <div class="p-field">
        <label for="mapsUuid">Карта</label>
        <Textarea id="mapsUuid" v-model="mapsUuid"/>
      </div>

      <div class="p-field">
        <label for="article">Артикул</label>
        <InputText id="article" v-model="article"></InputText>
      </div>


      <div class="p-field">
        <label for="activeNumbers">Номера активных сегментов</label>
        <Textarea id="activeNumbers" v-model="activeSegmentsNums"/>
      </div>

      <div class="p-field">
        <label for="discount">Множитель наценки для скидки</label>
        <InputNumber id="discount" v-model="discount" minFractionDigits="2" maxFractionDigits="2"/>
      </div>


      <div class="p-field">
        <label for="round">Округление</label>
        <InputNumber id="round" v-model="rounding"/>
      </div>

			<div class="p-field">
				<label for="dst-project">Техника для выполнения очистки от древесной растительности</label>
				<Dropdown
					id="dst-project"
					v-model="forestClearingBy"
					:options="forestClearingMachinery"
					:dropdown="true"
					field="name"
					:filter="true"
					:optionLabel="(value) => value.label"
					:optionValue="(value) => value"
				/>
			</div>

      <div class="p-field">
        <p><label for="cache">Включить работы по избавлению от борщевика</label></p>
        <Checkbox id="cache" v-model="hogweedEnabled" :binary="true"/>
      </div>

      <div class="p-field">
        <p><label for="cache">Загружать из кеша</label></p>
        <Checkbox id="cache" v-model="cacheEnabled" :binary="true"/>
      </div>

    </div>

    <div class="p-formgroup-inline">
      <div class="p-field">
        <Button v-on:click.prevent="addOffer">Добавить</Button>
      </div>

      <div class="p-field">
        <Button v-on:click.prevent="addOffers">Добавить все</Button>
      </div>

      <div class="p-field">
        <Button class="p-button-success" v-on:click.prevent="generate">Сгенерировать</Button>
      </div>
    </div>

    <div v-if="offers.length">
      <DataTable :value="offers">
        <Column field="projectIdProd" header="Проект в МГ Боевой"></Column>
        <Column field="projectIdAgro" header="Проект в МГ СХ обработка"></Column>
        <Column field="imageryId" header="Карты"></Column>
        <Column field="batchArticle" header="Артикул актива"></Column>
        <Column field="discountPercent" header="Процент скидки"></Column>
        <Column field="priceRounding" header="Округлять цену до"></Column>
        <Column field="forestClearingBy" header="Техника для выполнения очистки от древесной растительности"></Column>
        <Column field="withHogweedProcessing" header="Борьба с борщевиком включена"></Column>
        <Column field="withCache" header="Кэширование включено"></Column>

        <Column :exportable="false">
          <template #body="slotProps">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteOffer(slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </div>

  </div>
</template>

<script>
import InputNumber from "primevue/inputnumber/InputNumber";
import DataTable from "primevue/datatable/DataTable";
import Column from "primevue/column/Column";
import ProgressBar from "primevue/progressbar/ProgressBar";
import Message from "primevue/message/Message";
import Button from "primevue/button/Button";
import Dropdown from "primevue/dropdown/Dropdown";
import AgroGenerator from "@/services/agro/AgroGenerator.js";
import AgroSearcher from "@/services/catalogue/AgroSearcher.js";
import Textarea from "primevue/textarea/Textarea";
import InputText from "primevue/inputtext/InputText";
import Checkbox from "primevue/checkbox/Checkbox";

export default {
  components: {
    InputNumber: InputNumber,
    DataTable: DataTable,
    Column: Column,
    ProgressBar: ProgressBar,
    Message: Message,
    Button: Button,
    Dropdown: Dropdown,
    Textarea: Textarea,
    InputText: InputText,
    Checkbox: Checkbox,
  },
  data() {
    return {
      cfgMap: [],
      dstProjects: [],
      srcProjects: [],
      articles: [],
			forestClearingMachinery: [
				{
					key: 'mulcher',
					label: 'Мульчер',
				},
				{
					key: 'tractor',
					label: 'Трактор',
				},
			],

      pidProd: null,
      pidSegments: null,
      mapsUuid: '',
      article: null,
      discount: 0.3,
      activeSegmentsNums: '',
      rounding: 1000,
      cacheEnabled: false,
      hogweedEnabled: false,
			forestClearingBy: {
				key: 'mulcher',
				label: 'Мульчер',
			},

      loadingData: false,
      offers: [],
      jobId: null,
      offerUrl: false,
      progress: 0,
    }
  },
  agroSearcher: null,
  agroGenerator: null,
  created() {
    this.agroSearcher = new AgroSearcher;
    this.agroGenerator = new AgroGenerator;

    this.agroSearcher.sourceProjects().then((sourceProjects) => this.srcProjects = sourceProjects);
    this.agroSearcher.destinationProjects().then((dstProjects) => this.dstProjects = dstProjects);
    this.agroSearcher.configMap().then((configMap) => this.cfgMap = configMap);

  },
  methods: {
    addOffer() {
      if(!this.validateOffer()){
        return;
      }

      const articles = this.article.split(',');
      articles.forEach(article => {
        this.offers.push({
          batchArticle: article,
          projectIdProd: this.pidProd,
          projectIdAgro: this.pidSegments,
          imageryId: this.mapsUuid,
          discountPercent: this.discount,
          priceRounding: this.rounding,
          withCache: this.cacheEnabled,
          withHogweedProcessing: this.hogweedEnabled,
					forestClearingBy: this.forestClearingBy.key,
        })
      })

      this.clearInputs();
    },
    validateOffer() {
      return !!this.article && !!this.pidSegments && !!this.pidProd;
    },
    clearInputs() {
      this.pidProd = null;
      this.pidSegments = null;
      this.mapsUuid = '';
      this.article = null;
    },

    addOffers() {
      this.getOffers().map((value) => this.offers.push(value))
    },

    getOffers() {
      return this.articles.map((value) => {
        return {article: value.article, price: null}
      });
    },


    generate: function () {
      this.agroGenerator.generateRequest(this.offers).then((generateResult) => {
        console.log(generateResult);
        generateResult && (this.loadingData = true) && (this.jobId = generateResult.id) && this.info(generateResult);
      })
    },

    info: function (generateResult) {
      this.agroGenerator.statusRequest(generateResult).then((jobStatus) => {
        (jobStatus === false) && (this.loadingData = false);

        if(jobStatus.complete === true){
          this.offerUrl = jobStatus.url;
        }else{
          this.updateProgress(jobStatus);
          setTimeout(() => this.info(generateResult), 5000)
        }
      })
    },

    updateProgress(jobStatus) {
      this.progress = this.$filters.percentage(jobStatus.done, jobStatus.total);
      return true;
    },

    deleteOffer(offer) {
      this.offers = this.offers.filter(val => !(
          val.article === offer.article
      ))
    },
    updateFieldsBySource(event) {
      let srcProjectId = event.value;

      let projectData = this.cfgMap[srcProjectId];

      this.pidSegments = projectData.dstProject.toString();
      this.mapsUuid = projectData.imagery;
      this.article = projectData.article;
    }
  }
}
</script>