<template>
  <div>
    <div class="uk-container">
      <div class="w-main-cardbox">
        <div class="uk-card" v-for="(offer, key) in humanPayload" :field="offer.field" :header="offer.header" v-bind:key="key">
          <div class="uk-flex uk-flex-between uk-flex-column-s">
            <div>
              <div class="uk-flex uk-flex-bottom@s uk-flex-column-s uk-text-left uk-margin-small-s">
                <p class="uk-margin-small-right uk-margin-remove-vertical">Поселок</p>
                <p class="uk-text-bold uk-margin-remove uk-text-center@s uk-text-left">{{ offer.projectName }}</p>
              </div>
              <div class="uk-flex uk-flex-bottom@s uk-flex-column-s  uk-text-left">
                <p class="uk-margin-small-right uk-margin-remove-vertical">Участок</p>
                <p class="uk-text-bold uk-margin-remove uk-text-center@s uk-text-left">{{ offer.article }}</p>
              </div>
            </div>
            <div class="">
              <div class="uk-flex w-main-cardbox-btns uk-margin-small-top uk-flex-center">
                <ShareButton
                  v-bind:isShow="modelValue.finalize && offer.link"
                  v-bind:url="offerLink(offer.article)"
                  v-bind:title="offer.projectName + ' ' + offer.article"
                ></ShareButton>
                <a title="Посмотреть"
                   v-if="modelValue.finalize && offer.link"
                   @click="openInNewTab(offer.link)"
                   class="uk-margin-right uk-text-center uk-link-reset"
                >
                  <img src="/assets/vw-sm.svg" style="min-width: 34px" alt="">
                  <p class="uk-margin-remove " style="font-size: 10px;">Посмотреть</p>
                </a>
                <a v-if="modelValue.finalize && offer.downloadLink"
                   class="p-button-outlined p-button-success  uk-text-center uk-link-reset"
                   @click="openInNewTab(offer.downloadLink)"
                >
                  <img src="/assets/dwnld-sm.svg" style="min-width: 34px"  alt="">
                  <p class="uk-margin-remove  " style="font-size: 10px;">Скачать</p>
                </a>
                <div v-else uk-spinner></div>
              </div>
            </div>
          </div>
        </div>
        <DownloadAllButton
          v-bind:downloadUrl="modelValue.downloadUrl"
          v-bind:total="modelValue.total"
          v-bind:finalized="modelValue.finalize"
        ></DownloadAllButton>
      </div>
    </div>
  </div>
</template>

<script>

import DownloadAllButton from "@/components/common/DownloadAllButton";
import ShareButton from "@/components/common/ShareButton";

export default {
  data() {
    return {}
  },
  components: {
    DownloadAllButton: DownloadAllButton,
    ShareButton: ShareButton
  },
  props: {
    modelValue: {
      type: Object
    },
    humanPayload: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    offerLink: function (article) {
      return location.protocol + '//' + location.host +'/batch/presentation?article=' + article;
    }
  }

}
</script>

<style lang="css" scoped>

.w-main-cardbox {
  margin-bottom: 3.8rem;
}

.uk-card {
  max-width: 642px;
  border-radius: 8px;
  padding: 20px;
  font-size: 13px;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: #000000;
  background: white;
}

.uk-text-bold {
  font-size: 14px;
}

small {
  color: #000000;
  font-size: 14px;
  margin-bottom: 2rem;
  display: block;
}

</style>

<style>
.uk-spinner > * > * {
  color: red;
}

@media (max-width: 768px)  {
  .uk-flex-column-s{
    flex-direction: column;
  }
  .w-main-cardbox-btns{
    padding-top: 1rem;
  }
  .uk-margin-small-s{
    margin-bottom: 1rem;
  }
}
</style>
