<template>
  <div class="p-col-12">
    <div v-if="loadingData && !offerUrl">
      <ProgressBar :value="progress"/>
      <Message icon="pi-spin pi-spinner" :closable="false">
        КП формируется. Идентификатор: {{ jobId }}
      </Message>
    </div>

    <div v-if="offerUrl">
      <Message severity="success" :closable="false">
        КП сформирован. <a v-bind:href="offerUrl">Скачать</a>
      </Message>
    </div>

    <div class="p-fluid">
      <div class="p-field">
        <label for="project">Проект</label>
        <Dropdown
            id="project"
            v-model="projectId"
            :options="projects"
            :dropdown="true"
            field="title"
            :filter="true"
            :optionLabel="(value) => value.title"
            :optionValue="(value) => value.id"
            emptyFilterMessage="По вашему запросу ничего не найдено"
            @change="loadRevisions"
        />
      </div>

      <div class="p-field">
        <label for="revision">Версия</label>
        <Dropdown
            id="revision"
            v-model="revisionId"
            :options="revisions"
            :dropdown="true"
            field="name"
            :filter="true"
            :optionLabel="(value) => value.name"
            :optionValue="(value) => value.id"
            emptyFilterMessage="По вашему запросу ничего не найдено"
            @change="loadEntrances"
        />
      </div>

      <div class="p-field">
        <label for="entrance">Въезд</label>
        <Dropdown
            id="entrance"
            v-model="entranceId"
            :options="entrances"
            :dropdown="true"
            field="name"
            :filter="true"
            :optionLabel="(value) => value.name"
            :optionValue="(value) => value.id"
            emptyFilterMessage="По вашему запросу ничего не найдено"
            @change="loadUsers"
        />
      </div>

      <div class="p-field">
        <label for="user">Пользователь</label>
        <Dropdown
            id="user"
            v-model="counterpartyId"
            :options="users"
            :dropdown="true"
            field="fio"
            :filter="true"
            :optionLabel="(value) => value.fio"
            :optionValue="(value) => value.id"
            emptyFilterMessage="По вашему запросу ничего не найдено"
        />
      </div>

    </div>

    <div class="p-formgroup-inline">
      <div class="p-field">
        <Button v-on:click.prevent="addOffer">Добавить</Button>
      </div>

      <div class="p-field">
        <Button v-on:click.prevent="addOffers">Добавить все</Button>
      </div>

      <div class="p-field">
        <Button class="p-button-success" v-on:click.prevent="generate">Сгенерировать</Button>
      </div>

      <div class="p-field">
        <Button class="p-button-danger" v-on:click.prevent="test">Тест</Button>
      </div>
    </div>

    <div v-if="offers.length">
      <DataTable :value="offers">
        <Column field="revisionId" header="Версия"/>
        <Column field="entranceId" header="Въезд"/>
        <Column field="counterpartyId" header="Пользователь"/>
        <Column :exportable="false">
          <template #body="slotProps">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteOffer(slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </div>

  </div>
</template>

<script>
import DataTable      from "primevue/datatable/DataTable";
import Column         from "primevue/column/Column";
import ProgressBar    from "primevue/progressbar/ProgressBar";
import Message        from "@/components/common/Message";
import Button         from "primevue/button/Button";
import Dropdown       from "primevue/dropdown/Dropdown";
import RoadsSearcher  from "@/services/roads/RoadsSearcher.js";
import RoadsGenerator from "@/services/roads/RoadsGenerator.js";

export default {
  components    : {
    DataTable  : DataTable,
    Column     : Column,
    ProgressBar: ProgressBar,
    Message    : Message,
    Button     : Button,
    Dropdown   : Dropdown,
  },
  data() {
    return {
      projects   : [],
      revisions  : [],
      entrances  : [],
      users      : [],
      projectId  : null,
      revisionId : null,
      entranceId : null,
      counterpartyId   : null,
      loadingData: false,
      jobId      : '',
      offers     : [],
      offerUrl   : false,
      progress   : 0,
    }
  },
  mounted() {
    this.loadProjects();
  },
  roadsSearcher : null,
  roadsGenerator: null,
  created() {
    this.roadsSearcher  = new RoadsSearcher;
    this.roadsGenerator = new RoadsGenerator;
  },
  methods       : {
    loadProjects() {
      this.roadsSearcher.searchProjects().then((projects) => this.projects = projects)
    },
    loadRevisions() {
      this.projectId &&
      this.roadsSearcher.searchRevisions(this.projectId).then((revisions) => this.revisions = revisions)
    },
    loadEntrances() {
      this.revisionId &&
      this.roadsSearcher.searchEntrances(this.revisionId).then(entrances => this.entrances = entrances)
    },
    loadUsers() {
      this.revisionId && this.entranceId &&
      this.roadsSearcher.searchCounterparties(this.revisionId, this.entranceId).then(users => this.users = users)
    },

    addOffer() {
      this.revisionId && this.entranceId && this.counterpartyId && (this.offers.push({
        revisionId: this.revisionId,
        entranceId: this.entranceId,
        counterpartyId  : this.counterpartyId
      }) && this.clearInputs());
    },

    clearInputs() {
      this.userUuid = null;
    },

    addOffers() {
      this.getOffers().map((value) => this.offers.push(value))
    },

    getOffers() {
      return this.users.map((value) => {
        return {
          revisionId: this.revisionId,
          entranceId: this.entranceId,
          counterpartyId  : value.id
        }
      });
    },


    generate: function () {
      this.roadsGenerator.generateRequest(this.offers).then((generateResult) => {
        console.log(generateResult);
        generateResult && (this.loadingData = true) && (this.jobId = generateResult.id) && this.info(generateResult);
      })
    },

    info: function (generateResult) {
      this.roadsGenerator.statusRequest(generateResult).then((jobStatus) => {
        (jobStatus === false) && (this.loadingData = false);

        (jobStatus.url) ? (this.offerUrl = jobStatus.url) : this.updateProgress(jobStatus) && setTimeout(() => this.info(generateResult), 5000);
      })
    },

    updateProgress(jobStatus) {
      this.progress = this.$filters.percentage(jobStatus.done, jobStatus.total);
      return true;
    },

    deleteOffer(offer) {
      this.offers = this.offers.filter(val => !(
          val.revisionId === offer.revisionId &&
          val.entranceId === offer.entranceId &&
          val.counterpartyId === offer.counterpartyId
      ))
    },

    test() {
      this.offers.push({
        revisionId: 160,
        entranceId: 1,
        counterpartyId  : '19ee04c4-0636-4a3b-8bf4-5142ed73d84e'
      })
    }
  }
}
</script>
