"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseGenerator_1 = require("../base/BaseGenerator");
var axios_1 = require("axios");
var BatchGenerator = /** @class */ (function (_super) {
    __extends(BatchGenerator, _super);
    function BatchGenerator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BatchGenerator.prototype.generateRequest = function (offers) {
        return _super.prototype.generateRequest.call(this, offers);
    };
    BatchGenerator.prototype.clientGenerateRequest = function (article) {
        var _this = this;
        return axios_1.default.get(this.getClientGenerateUrl(), { params: { article: article } }).then(function (response) {
            return _this.isResponseValid(response) ? { id: response.data.id } : false;
        });
    };
    BatchGenerator.prototype.getGenerateUrl = function () {
        return process.env.VUE_APP_API_URL + "/api/v1/catalog/generate/multiple";
    };
    BatchGenerator.prototype.getClientGenerateUrl = function () {
        return process.env.VUE_APP_API_URL + "/api/v1/catalog/generate/article";
    };
    BatchGenerator.prototype.getStatusUrl = function (jobId) {
        return process.env.VUE_APP_API_URL + '/api/v1/catalog/status/multiple?id=' + jobId;
    };
    return BatchGenerator;
}(BaseGenerator_1.default));
exports.default = BatchGenerator;
