<template>
  <DataTable :value="offers"
             :lazy="true"
             :paginator="true"
             :rows="perPage"
             :totalRecords="totalCount"
             :loading="isLoading"
             :sortField="sortField"
             :sortOrder="sortOrder"
             :filters="filters"
             @filter="filterById"
             @page="onPage($event)"
             @sort="onSort($event)"
  >
    <Column field="id" filterField="id" header="Идентификатор">
      <template #filter>
        <InputText class="p-column-filter" type="text" v-model="filters['id']" @change="filterById" placeholder="Поиск по ID"/>
      </template>

      <template #body="slotProps">
        <span class="identity-column">{{slotProps.data.id}}</span>
      </template>
    </Column>
    <Column field="finalize" header="Готовность" :sortable="true">
      <template #body="slotProps">
        <ProgressBar
            :value="slotProps.data.finalize ? 100 : $filters.percentage(slotProps.data.done, slotProps.data.total)
          ">
          <span class="progressbar-label">{{slotProps.data.done + "/" + slotProps.data.total}}</span>
        </ProgressBar>
      </template>
    </Column>
    <Column field="created_at" header="Создана" :sortable="true">
      <template #body="slotProps">
        {{ $filters.unixToHuman(slotProps.data.created_at) }}
      </template>
    </Column>
    <Column field="done_at" header="Выполнена">
      <template #body="slotProps">
        {{ $filters.unixToHuman(slotProps.data.done_at) }}
      </template>
    </Column>

    <Column :exportable="false">
      <template #body="slotProps">
        <Button
            title="Скачать"
            v-if="slotProps.data.downloadUrl"
            icon="pi pi-download"
            class="p-button-rounded p-button-success"
            @click="openInNewTab(slotProps.data.downloadUrl)"
        />
        <Button title="Подробней" icon="pi pi-info" class="p-button-rounded p-button-primary" @click="showDetail(slotProps.data)"/>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import _               from 'lodash';

export default {
  data() {
    return {
      offers     : [],
      filters    : [],
      currentPage: 1,
      pageCount  : null,
      perPage    : 20,
      totalCount : null,
      isLoading  : true,
      sortField  : 'created_at',
      sortOrder  : -1,
      autoUpdate : true,

      showDetailModal : false,
      detailModalData: null,
    }
  },
  emits: [
    'detail-offer'
  ],
  props: {
    jobClient: null,
    generator: null,
  },
  interval: null,
  mounted() {
    this.loadList(1, this.sortField, this.sortOrder)

    this.interval = setInterval(() => {
      this.autoUpdate && this.loadList(this.currentPage, this.sortField, this.sortOrder, false)
    }, 5000)
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods  : {
    onPage(event) {
      this.loadListDebounced(event.page + 1, event.sortField, event.sortOrder, this);
    },

    onSort(event) {
      this.loadListDebounced(1, event.sortField, event.sortOrder, this);
    },

    loadListDebounced: _.debounce((page, sortField, sortOrder, vm) => {
      vm.loadList(page, sortField, sortOrder);
    }, 1000),

    loadList(page = 1, sortField, sortOrder, showLoading = true) {
      showLoading && (this.isLoading = true);

      this.sortField = sortField;
      this.sortOrder = sortOrder;

      let isFiltered = this.filterById()

      !!isFiltered || this.jobClient.list(page, sortField, sortOrder).then((list) => {
        this.loadValues(list)
        showLoading && (this.isLoading = false);
      })
    },

    loadValues(list) {
      this.offers      = list.offers;
      this.currentPage = list.currentPage;
      this.pageCount   = list.pageCount;
      this.perPage     = list.perPage;
      this.totalCount  = list.totalCount;
    },


    showDetail(offer) {
      this.$emit('detail-offer', offer)
    },

    filterById() {
      let id = this.filters['id']

      if (!id || (typeof filter === 'string' && id.trim() === '')) {
        return false;
      }

      return this.jobClient.detail(id).then((offer) => {
        this.currentPage = 1;
        this.totalCount = 1;
        return this.offers = [offer];
      })
    }
  }
}
</script>

<style scoped>
.identity-column {
  font-size: 10px;
}

.progressbar-label {
  font-size: 12px;
  font-weight: 530;
  color: #ffffff;
}
</style>