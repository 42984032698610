<template>
	<div class="p-col-12">
		<div v-if="loadingData && !offerUrl">
			<ProgressBar :value="progress"/>
			<Message icon="pi-spin pi-spinner" :closable="false">
				КП формируется. Идентификатор: {{ jobId }}
			</Message>
		</div>

		<div v-if="offerUrl">
			<Message severity="success" :closable="false">
				КП сформирован. <a v-bind:href="offerUrl">Скачать</a>
			</Message>
		</div>

		<div class="p-fluid">
			<div class="p-field">
				<label for="batch">Участок</label>
				<Dropdown
					id="batch"
					v-model="article"
					@filter="onSearch"
					:options="articles"
					:dropdown="true"
					field="name"
					:filter="true"
					:optionLabel="(value) => value.article + ' - ' + value.name"
					:optionValue="(value) => value.article"
					emptyFilterMessage="По вашему запросу ничего не найдено"
				/>
			</div>

			<div class="p-field">
				<label for="price">Цена</label>
				<InputNumber id="price" v-model="price" mode="currency" currency="RUB" locale="ru-RU"/>
			</div>

      <div class="p-field">
        <label for="price">Цена со скидкой</label>
        <InputNumber id="price" v-model="discountedPrice" mode="currency" currency="RUB" locale="ru-RU" :disabled="price < 1"/>
      </div>

			<label for="cacheEnabled">Использовать кэш</label>
			<div class="p-field-checkbox">
				<Checkbox id="cacheEnabled" v-model="cacheEnabled" :binary="true"/>
			</div>

		</div>

		<div class="p-formgroup-inline">
			<div class="p-field">
				<Button v-on:click.prevent="addOffer">Добавить</Button>
			</div>

			<div class="p-field">
				<Button v-on:click.prevent="addOffers">Добавить все</Button>
			</div>

			<div class="p-field">
				<Button class="p-button-success" v-on:click.prevent="generate">Сгенерировать</Button>
			</div>
		</div>

		<div v-if="offers.length">
			<DataTable :value="offers">
				<Column field="article" header="Артикул"></Column>
				<Column field="price" header="Цена">
					<template #body="slotProps">
						{{ slotProps.data.price ? slotProps.data.price : "Не указано" }}
					</template>
				</Column>
        <Column field="price" header="Цена со скидкой">
          <template #body="slotProps">
            {{ slotProps.data.discountedPrice ? slotProps.data.discountedPrice : "Не указано" }}
          </template>
        </Column>
				<Column field="price" header="Кэш">
					<template #body="slotProps">
						{{ slotProps.data.cacheEnabled ? '+' : '-' }}
					</template>
				</Column>
				<Column :exportable="false">
					<template #body="slotProps">
						<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteOffer(slotProps.data)"/>
					</template>
				</Column>
			</DataTable>
		</div>

	</div>
</template>

<script>
import _              from 'lodash';
import BatchSearcher  from "@/services/catalogue/BatchSearcher.js";
import BatchGenerator from "@/services/catalogue/BatchGenerator.js";
import InputNumber    from "primevue/inputnumber/InputNumber";
import DataTable      from "primevue/datatable/DataTable";
import Column         from "primevue/column/Column";
import ProgressBar    from "primevue/progressbar/ProgressBar";
import Message        from "@/components/common/Message";
import Button         from "primevue/button/Button";
import Dropdown       from "primevue/dropdown/Dropdown";
import Checkbox       from "primevue/checkbox/Checkbox";

export default {
  components: {
    InputNumber: InputNumber,
    DataTable  : DataTable,
    Column     : Column,
    ProgressBar: ProgressBar,
    Message    : Message,
    Button     : Button,
    Dropdown   : Dropdown,
    Checkbox   : Checkbox,
  },
  data() {
    return {
      articles    : [],
      article     : null,
      loadingData : false,
      price       : undefined,
      discountedPrice    : undefined,
      cacheEnabled: true,
      offers      : [],
      jobId       : null,
      offerUrl    : false,
      progress    : 0,
    }
  },
  batchSearcher : null,
  batchGenerator: null,
  created() {
    console.log(this.$auth.check('Administrator'));
    this.batchSearcher  = new BatchSearcher;
    this.batchGenerator = new BatchGenerator;
  },
  methods: {
    onSearch(event) {
      this.search(event.value, this);
    },

    search: _.debounce((search, vm) => {
      vm.batchSearcher.search(search)
          .then(data => vm.articles = data);
    }, 1000),

    addOffer() {
      !this.article || (this.offers.push({
        article     : this.article,
        price       : this.price,
        discountedPrice    : this.discountedPrice,
        cacheEnabled: this.cacheEnabled,
      }) && this.clearInputs());
    },

    clearInputs() {
      this.price        = null;
      this.discountedPrice     = null;
      this.article      = null;
      this.cacheEnabled = true;
    },

    addOffers() {
      this.getOffers().map((value) => this.offers.push(value))
    },

    getOffers() {
      return this.articles.map((value) => {
        return {article: value.article, price: null, cacheEnabled: this.cacheEnabled, discountedPrice: null}
      });
    },


    generate: function () {
      this.batchGenerator.generateRequest(this.offers).then((generateResult) => {
        console.log(generateResult);
        generateResult && (this.loadingData = true) && (this.jobId = generateResult.id) && this.info(generateResult);
      })
    },

    info: function (generateResult) {
      this.batchGenerator.statusRequest(generateResult).then((jobStatus) => {
        (jobStatus === false) && (this.loadingData = false);

        (jobStatus.url) ? (this.offerUrl = jobStatus.url) : this.updateProgress(jobStatus) && setTimeout(() => this.info(generateResult), 60000);
      })
    },

    updateProgress(jobStatus) {
      this.progress = this.$filters.percentage(jobStatus.done, jobStatus.total);
      return true;
    },

    deleteOffer(offer) {
      this.offers = this.offers.filter(val => !(
          val.article === offer.article
      ))
    },
  }
}
</script>
