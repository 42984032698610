<template>
  <a title="Поделиться"
     v-if="isShow && isSupported"
     @click="share"
     class="uk-margin-right uk-text-center uk-link-reset"
  >
    <img src="/assets/share.svg" class="btn_share" style="" alt="">
    <p class="uk-margin-remove" style="font-size: 10px;">Поделиться</p>
  </a>
</template>

<script>

export default {
  name: 'ShareButton',
  props: [
    'isShow',
    'url',
    'Title',
  ],
  computed: {
    isSupported() {
      return navigator.share
    }
  },
  methods : {
    share() {
      if (navigator.share) {
        navigator.share({
          title: this.title,
          url: this.url
        }).then(() => {
        }).catch(console.error);
      } else {
        console.log("Share is not supported");
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .btn_share  {
    min-width: 30px;
    max-height: 34px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  @media (max-width: 640px)  {
    .btn_share  {
      max-height: 30px;
      padding-bottom: 0;
      margin-bottom: 4px;
    }
  }
</style>