"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var BaseGenerator = /** @class */ (function () {
    function BaseGenerator() {
    }
    BaseGenerator.prototype.generateRequest = function (offers) {
        var _this = this;
        return axios_1.default.post(this.getGenerateUrl(), { offers: offers }).then(function (response) {
            return _this.isResponseValid(response) ? { id: response.data.id } : false;
        });
    };
    BaseGenerator.prototype.statusRequest = function (generateResult) {
        var _this = this;
        var url = this.getStatusUrl(generateResult.id);
        return axios_1.default.get(url).then(function (response) {
            return _this.isResponseValid(response)
                ? response.data
                : false;
        }, function (error) {
            console.log(error);
            return false;
        });
    };
    BaseGenerator.prototype.isResponseValid = function (response) {
        console.log("Response validated: " + !response.data.message);
        return !response.data.message;
    };
    return BaseGenerator;
}());
exports.default = BaseGenerator;
