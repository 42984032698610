"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var JobsClient = /** @class */ (function () {
    function JobsClient() {
    }
    JobsClient.prototype.list = function (page, sortField, sortOrder) {
        var _this = this;
        return axios_1.default.post(this.getListUrl(page), { sort: this.getSort(sortField, sortOrder) })
            .then(function (response) {
            var offers = Array.isArray(response.data) ? response.data.map(function (job) { return _this.populateJob(job); }) : [];
            return {
                offers: offers,
                currentPage: Number.parseInt(response.headers['x-pagination-current-page']),
                pageCount: Number.parseInt(response.headers['x-pagination-page-count']),
                perPage: Number.parseInt(response.headers['x-pagination-per-page']),
                totalCount: Number.parseInt(response.headers['x-pagination-total-count']),
            };
        });
    };
    JobsClient.prototype.detail = function (id) {
        var _this = this;
        return axios_1.default.post(this.getListUrl(1) + '&expand=humanPayload,payload', { filter: { id: id } })
            .then(function (response) {
            var total = Number.parseInt(response.headers['x-pagination-total-count']);
            return (total !== 1) ? null : _this.populateJob(response.data[0]);
        });
    };
    JobsClient.prototype.populateJob = function (job) {
        return {
            id: job.id,
            total: Number.parseInt(job.total),
            done: Number.parseInt(job.done),
            finalize: job.finalize,
            error: job.error ? JSON.parse(job.error) : job.error,
            created_at: Date.parse(job.created_at),
            done_at: typeof job.done_at === 'string' ? Date.parse(job.done_at) : job.done_at,
            downloadUrl: job.downloadUrl,
            payload: job.payload ? JSON.parse(job.payload) : job.payload,
            humanPayload: job.humanPayload,
        };
    };
    JobsClient.prototype.getSort = function (sortField, sortOrder) {
        return sortOrder > 0 ? sortField : '-' + sortField; // yii order
    };
    return JobsClient;
}());
exports.default = JobsClient;
