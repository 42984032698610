<template>
<div></div>
  <Button title="Пересоздать задачу" icon="pi pi-refresh" class="p-button-rounded p-button-secondary"
          @click="regenerateOffer(modelValue)"/>
  <h3>{{ "Задача #" + modelValue.id }}</h3>

  <table class="p-datatable">
    <tbody class="p-datatable-tbody">
    <tr>
      <td>Выполнено</td>
      <td>{{ modelValue.done }}</td>
    </tr>

    <tr>
      <td>Всего</td>
      <td>{{ modelValue.total }}</td>
    </tr>

    <tr>
      <td>Создана</td>
      <td>{{ $filters.unixToHuman(modelValue.created_at) }}</td>
    </tr>

    <tr>
      <td>Выполнена</td>
      <td>{{ $filters.unixToHuman(modelValue.done_at) }}</td>
    </tr>
    </tbody>
  </table>


  <div v-if="modelValue.error">
    <h3>Ошибки</h3>
    <table class="p-datatable">
      <tbody class="p-datatable-tbody">
      <tr v-for="(error, key) in modelValue.error" v-bind:key="key">
        <td>{{ error.message }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-if="modelValue.humanPayload">
    <h3>КП</h3>
    <DataTable :value="modelValue.humanPayload">
      <Column v-for="(column, key) in payloadColumns" :field="column.field" :header="column.header" v-bind:key="key"/>
      <Column :exportable="false">
        <template #body="slotProps">
          <Button
              title="Скачать"
              v-if="slotProps.data.link"
              icon="pi pi-download"
              class="p-button-rounded p-button-success"
              @click="openInNewTab(slotProps.data.link)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="p-text-center" v-else>
    <ProgressSpinner/>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  emits: ['update:modelValue'],
  props  : {
    modelValue: {
      type: Object
    },
    payloadColumns: {
      type: Array,
      default: () => []
    },
    jobClient: null,
    generator: null,
  },
  mounted() {
    console.log(this.jobClient);
    this.showDetail();
  },
  methods: {
    showDetail() {
      console.log(this.modelValue.id);
      this.jobClient ? this.jobClient.detail(this.modelValue.id).then((offer) => {
        this.updateValue(offer !== null ? offer : this.value);
      }) : null;
    },

    regenerateOffer(offer) {
      this.generator.generateRequest(offer.payload).then((generateResult) => {
        if (!generateResult) {
          return Promise.reject(generateResult);
        }

        this.updateValue(generateResult);
        return Promise.resolve(generateResult);
      }).then(() => {
        this.showDetail();
      })
    },

    updateValue(value) {
      this.$emit('update:modelValue', value);
    }
  }
}
</script>