<template>
  <div class="uk-text-center w-main-dwnld-box">
    <Button
        v-if="isShowButton"
        title="Скачать"
        class="uk-button uk-button-danger w-main-dwnld uk-box-shadow-hover-medium"
        @click="openInNewTab(downloadUrl)"
    >
      Скачать все <img src="/assets/dwnld-wt.svg" class="uk-margin-small-left uk-margin-small" alt="">
    </Button>
  </div>
</template>

<script>
export default {
  name: "DownloadAllButton",
  props: [
    'downloadUrl',
    'finalized',
    'total',
  ],
  computed: {
    isShowButton: function () {
      return this.downloadUrl && this.finalized
    }
  }
}
</script>

<style lang="css" scoped>
.w-main-dwnld{
  background: #FF0013;
  box-sizing: border-box;
  border-radius: 8px;
  height: 56px;
  width: 442px;
  font-size: 1rem;
  line-height: 3.5;
}
.w-main-dwnld:hover,.w-main-dwnld:active{
  background: #FF0013;
}

small{
  color: #000000;
  font-size: 14px;
  margin-bottom: 2rem;
  display: block;
}

@media (max-width: 500px) {
  .w-main-dwnld{
    background: #FF0013;
    box-sizing: border-box;
    border-radius: 8px;
    height: 46px;
    width: 95%;
    font-size: 13px;
    line-height: 3.5;
    position: fixed;
    bottom: 1rem;
    left: 2.5%;
  }
  .w-main-logo{
    width: 70px;
  }

}

</style>