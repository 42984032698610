<template>
  <OffersDataTable :generator="generator" :job-client="jobClient" v-on:detail-offer="showDetail"/>

  <Dialog v-model:visible="showDetailModal" style="width: 80%">
    <OfferDetailView v-model="offer" :job-client="jobClient" :generator="generator" :payload-columns="payloadColumns" @update:v-model="updateOfferValue"/>
  </Dialog>
</template>

<script>
import OfferDetailView from "@/components/common/OfferDetailView";
import OffersDataTable from "@/components/common/OffersDataTable";
import BatchJobsClient from "@/services/catalogue/BatchJobsClient.js";
import BatchGenerator  from "@/services/catalogue/BatchGenerator.js";

export default {
  components: {
    OffersDataTable: OffersDataTable,
    OfferDetailView: OfferDetailView,
  },
  data() {
    return {
      payloadColumns: [
        {field: "article", header: "Артикул"},
      ],
      offers         : [],
      offer          : null,
      showDetailModal: false,
      jobClient : null,
      generator : null,
    }
  },
  interval  : null,
  created() {
    this.jobClient = new BatchJobsClient;
    this.generator = new BatchGenerator;
  },
  methods   : {
    showDetail(offer) {
      this.offer           = offer;
      this.showDetailModal = true;
    },
    updateOfferValue(value) {
      this.offer = value;
      console.log(value);
    }
  }
}
</script>