"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Url = /** @class */ (function () {
    function Url() {
    }
    Url.queryString = function (params, keys, isArray) {
        if (keys === void 0) { keys = []; }
        if (isArray === void 0) { isArray = false; }
        var p = Object.keys(params).map(function (key) {
            var val = params[key];
            if ("[object Object]" === Object.prototype.toString.call(val) || Array.isArray(val)) {
                if (Array.isArray(params)) {
                    keys.push("");
                }
                else {
                    keys.push(key);
                }
                return Url.queryString(val, keys, Array.isArray(val));
            }
            else {
                var tKey = key;
                if (keys.length > 0) {
                    var tKeys = isArray ? keys : keys.concat(key);
                    tKey = tKeys.reduce(function (str, k) { return "" === str ? k : "".concat(str, "[").concat(k, "]"); }, "");
                }
                if (isArray) {
                    return "".concat(tKey, "[]=").concat(val);
                }
                else {
                    return "".concat(tKey, "=").concat(val);
                }
            }
        }).join('&');
        keys.pop();
        return p;
    };
    Url.params = function (params) {
        return Url.queryString(params);
    };
    return Url;
}());
exports.default = Url;
