"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var Url_1 = require("../../util/Url");
var RoadsSearcher = /** @class */ (function () {
    function RoadsSearcher() {
    }
    RoadsSearcher.prototype.searchProjects = function () {
        return axios_1.default.get(this.getProjectsUrl())
            .then(function (response) {
            return Array.isArray(response.data)
                ? response.data.map(function (project) {
                    return {
                        id: project.id,
                        title: project.title,
                    };
                })
                : [];
        });
    };
    RoadsSearcher.prototype.searchRevisions = function (projectId) {
        return axios_1.default.get(this.getRevisionsUrl(projectId))
            .then(function (response) {
            return Array.isArray(response.data)
                ? response.data.map(function (revision) {
                    return {
                        id: revision.id,
                        name: revision.revisionName + (revision.is_head ? ' - Главная версия' : ''),
                    };
                })
                : [];
        });
    };
    RoadsSearcher.prototype.searchEntrances = function (revisionId) {
        return axios_1.default.get(this.getEntrancesUrl(revisionId))
            .then(function (response) {
            return Array.isArray(response.data)
                ? response.data.map(function (entrance) {
                    return {
                        id: entrance.id,
                        name: entrance.name,
                    };
                })
                : [];
        });
    };
    RoadsSearcher.prototype.searchUsers = function (revisionId, entranceId) {
        return axios_1.default.get(this.getUsersUrl(revisionId, entranceId))
            .then(function (response) {
            return Array.isArray(response.data)
                ? response.data.map(function (user) {
                    return {
                        uuid: user.uuid,
                        fio: user.fio,
                    };
                })
                : [];
        });
    };
    RoadsSearcher.prototype.searchCounterparties = function (revisionId, entranceId) {
        return axios_1.default.get(this.getCounterpartiesUrl(revisionId, entranceId))
            .then(function (response) {
            return Array.isArray(response.data)
                ? response.data.map(function (user) {
                    return {
                        id: user.id,
                        fio: user.fio,
                    };
                })
                : [];
        });
    };
    RoadsSearcher.prototype.getProjectsUrl = function () {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_ROADS_PROXY_PROJECT_URL;
    };
    RoadsSearcher.prototype.getRevisionsUrl = function (projectId) {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_ROADS_PROXY_REVISION_URL + ("?" + Url_1.default.params({ projectId: projectId }));
    };
    RoadsSearcher.prototype.getEntrancesUrl = function (revisionId) {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_ROADS_PROXY_ENTRANCE_URL + ("?" + Url_1.default.params({ revisionId: revisionId }));
    };
    RoadsSearcher.prototype.getUsersUrl = function (revisionId, entranceId) {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_ROADS_PROXY_USER_URL + ("?" + Url_1.default.params({
            revisionId: revisionId,
            entranceId: entranceId
        }));
    };
    RoadsSearcher.prototype.getCounterpartiesUrl = function (revisionId, entranceId) {
        return process.env.VUE_APP_API_URL + process.env.VUE_APP_ROADS_PROXY_COUNTERPARTY_URL + ("?" + Url_1.default.params({
            revisionId: revisionId,
            entranceId: entranceId
        }));
    };
    return RoadsSearcher;
}());
exports.default = RoadsSearcher;
