<template>

  <DownloadAllButton
      v-bind:downloadUrl="modelValue.downloadUrl"
      v-bind:total="modelValue.total"
  ></DownloadAllButton>

  <div v-if="modelValue.humanPayload">
    <h3>Коммерческие предложения</h3>
    <DataTable :value="modelValue.humanPayload">
      <Column v-for="(column, key) in payloadColumns" :field="column.field" :header="column.header" v-bind:key="key"/>
      <Column :exportable="false">
        <template #body="slotProps">
          <div class="buttons_td">
            <div class="button_wrap">
              <Button
                  title="Скачать"
                  v-if="modelValue.finalize && modelValue.downloadUrl"
                  icon="pi pi-cloud-download"
                  class="p-button-outlined p-button-success"
                  @click="openInNewTab(modelValue.downloadUrl)"
              />
              <Button
                  v-else
                  icon="pi pi-spin pi-spinner"
                  class="p-button-outlined p-button-secondary"
                  disabled="disabled"
              />
              <span class="presentation_button_label">Скачать</span>
            </div>
            <div class="button_wrap" >
              <Button
                  title="Посмотреть"
                  v-if="modelValue.finalize && slotProps.data.link"
                  icon="pi pi-eye"
                  class="p-button-outlined p-button-success"
                  @click="openInNewTab(slotProps.data.link)"
              />
              <Button
                  v-else
                  icon="pi pi-spin pi-spinner"
                  class="p-button-outlined p-button-secondary"
                  disabled="disabled"
              />
              <span class="presentation_button_label">Посмотреть</span>
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>

import DownloadAllButton from "@/components/common/DownloadAllButton";

export default {
  data() {
    return {}
  },
  components: {
    DownloadAllButton: DownloadAllButton
  },
  props  : {
    modelValue: {
      type: Object
    },
    payloadColumns: {
      type: Array,
      default: () => []
    },
  },
}
</script>